import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import Layout from "../../components/layout";
import Page from "../../components/page";
import SEO from "../../components/seo";

const SinglePage = (props) => {
  const {
    pageContext: { title, seo, uri },
  } = props;

  useEffect(() => {
    if (props.pageContext.translations[0].uri) {
      if (
        props.pageContext.translations[0].uri !== "/pl/gdzie-nas-znalezc/" &&
        props.pageContext.translations[0].uri !== "/where-to-buy/"
      ) {
        document.getElementById("lang-switch").href =
          props.pageContext.translations[0].uri;
      } else {
        document.querySelectorAll(".city-name").forEach((city, index) => {
          let locations = city.nextElementSibling;

          if (locations && locations.classList.contains("locations-grid")) {
            let arrow = document.createElement("span");
            arrow.classList.add("arrow");
            arrow.textContent = "➢";
            city.appendChild(arrow);
            arrow.style.transition = "transform 0.2s ease"; // Smooth rotation of arrow

            // Initially handle the first city separately
            if (index === 0) {
              arrow.style.transform = "rotate(90deg)"; // Rotate the arrow for the first item
            } else {
              arrow.style.transform = "rotate(0deg)"; // Default arrow state for others
            }

            // Toggle location visibility and arrow rotation on click
            city.addEventListener("click", function () {
              // Check if the arrow is rotated
              let isRotated = arrow.style.transform === "rotate(90deg)";

              // Get the computed style of locations to check if it's currently visible
              let computedStyle = window.getComputedStyle(locations);
              let isVisible = computedStyle.display !== "none"; // Check if it's not 'none'

              // Toggle visibility based on current state
              if (isVisible) {
                locations.style.display = "none"; // Hide the locations
              } else {
                locations.style.display = "flex"; // Show the locations
              }

              // Toggle arrow rotation
              arrow.style.transform = isRotated
                ? "rotate(0deg)"
                : "rotate(90deg)";
            });
          }
        });
      }
    }
  });

  return (
    <Layout>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={title}
            seoData={seo}
            uri={uri}
            header={{ siteTitle: " Shop" }}
            ogimage={props.pageContext.featuredImage?.node}
          />
          <Page data={props.pageContext} />
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  );
};
export default SinglePage;
